.light {
  --bannerhero-background-filter: grayscale(0);
  --bannerhero-anchor: #fbd84b;
  --bannerhero-anchor-hover: #c9ad3d;
}

.dark {
  --bannerhero-background-filter: grayscale(1);
  --bannerhero-anchor: #fff470;
  --bannerhero-anchor-hover: #fbd84b;
}

.section-banner__item {
  display: none;
  &:first-of-type {
    display: block;
  }
}

.section-banner__background {
  img {
    filter: var(--bannerhero-background-filter);
    -webkit-mask-image: linear-gradient(to top, transparent 1%, #000 10%);

    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: auto;
    width: 100%;

    -webkit-user-drag: none;
    user-select: none;
  }
}

.section-banner {
  background: linear-gradient(to right, #1f221f 50%, #151515 50%);

  position: relative;
  overflow: hidden;
  // height: 477px;
  &__section-banner__carousel{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__mask-click {
    position: absolute;
    z-index: 1;
    background-color: transparent;
    width: 100%;
    height: 100%;
  }

  &__bottom {
    z-index: 3;
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-block: 40px 10px;

    &__carousel {
      display: flex;
      align-items: center;
      width: max-content;
      justify-content: center;
      gap: 12px;

      &__chevron-right {
        transform: rotate(180deg);
      }

      img {
        cursor: pointer;
        -webkit-user-drag: none;
        user-select: none;
      }
    }

    &__offers {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__text {
        color: #ffffff;
        font-weight: 400;
        font-size: 1rem;
      }

      &__chevron {
        transform: rotate(270deg);
        cursor: pointer;
      }
    }
  }

  .section-banner__dots-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    &__childs {
      height: 8px;
      border: none;
      background-color: #f5f5f524;
      width: 27px;
      border-radius: 99rem;
      transition: width 0.3s ease;
    }

    &__childs__active {
      width: 60px;
      background-color: white;
    }
  }
}

.section-banner-container {
  position: relative;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  font-family: 'Simplon BP';

  &__content {
    width: 182px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 70px;

    &__title {
      color: white;
      font-weight: 500;
      font-size: 2.375rem;
      text-align: center;
      font-family: 'simplonoi-2018';

      &__highlighted {
        text-shadow: 0px 0px 4px #40d802;
        background: linear-gradient(69deg, #40D80226.33%, #90D604.61%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
      }
    }

    &__product {
      display: flex;
      flex-direction: column;

      &__mega {
        background: linear-gradient(45deg, #00DA1524.55%, rgba(165, 240, 0, 0.99) 79.74%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        line-height: 50px;
        font-weight: 500;

        display: flex;
        gap: 8px;
        align-items: center;

        &__number {
          text-shadow: 0px 0px 4px #40d802;
          font-size: 3.5rem;
          font-family: 'simplonoi-2018';
        }

        &__text {
          text-shadow: 0px 0px 2px #40d802;
          font-size: 1.25rem;
          font-family: 'Simplon BP';
        }
      }

      &__price {
        color: #ffffff;
        font-weight: 500;

        display: flex;
        flex-direction: column;
        margin-left: 20px;
        width: max-content;

        &__value {
          font-family: 'simplonoi-2018';
          justify-content: center;
          display: flex;
          gap: 2px;

          &__first-column {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &__for {
              font-size: 0.5rem;
            }

            &__real {
              font-size: 1rem;
            }
          }

          &__second-column {
            display: flex;
            align-items: center;
            line-height: 21.87px;
            font-size: 3.375rem;
          }

          &__third-column {
            display: flex;
            flex-direction: column;
            justify-content: center;
            &__cents {
              font-size: 1.5rem;
            }
            &__monthly {
              font-size: 1.125rem;
            }
          }
        }

        &__credit {
          font-family: 'Simplon BP';
          font-size: 0.5rem;
          text-align: right;
        }
      }
    }

    &__promotion {
      color: #ffffff;
      background-color: #3d3d3d;
      font-size: 0.75rem;
      font-weight: 500;

      display: flex;
      gap: 4px;
      padding-inline: 2px;
      align-items: center;
      width: max-content;
    }
  }

  &__anchor {
    position: relative;
    text-decoration: none;
    z-index: 2;
    color: #1f1f1f;
    background-color: var(--bannerhero-anchor);
    font-weight: 500;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 999px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    width: 100%;
    height: 48px;

    &:hover {
      background-color: var(--bannerhero-anchor-hover);
    }
  }
}

@media (min-width: 620px) {
  .section-banner {
    // height: 393px;

    &__background {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      height: 100%;
      img {
        height: unset;
        position: static;
        width: unset;
        object-fit: cover;
      }
    }

    &__bottom {
      margin-bottom: 30px;
      &__offers {
        display: none;
      }
    }
  }

  .section-banner-container {
    gap: 18px;
    &__content {
      margin-top: 24px;
      width: 250px;
      padding-block: 0;
      gap: 18px;

      &__title {
        line-height: 40px;
        font-size: 3rem;
        text-align: left;
      }

      &__product {
        display: flex;
        flex-direction: column;
        gap: 16px;
        &__mega {
          line-height: 58px;

          &__number {
            font-size: 4.5rem;
          }
        }
        &__price {
          align-self: flex-start;
          &__value {
            &__first-column {
              &__for {
                font-size: 0.875rem;
              }

              &__real {
                font-size: 1.375rem;
              }
            }

            &__second-column {
              font-size: 4rem;
            }

            &__third-column {
              &__cents {
                font-size: 1.875rem;
                line-height: 24px;
              }
              &__monthly {
                font-size: 1.25rem;
              }
            }
          }

          &__credit {
            font-size: 0.625rem;
          }
        }
      }

      &__promotion {
        font-size: 0.875rem;
      }
    }
    &__anchor {
      width: max-content;
      padding-inline: 22px;
    }
  }
}

@media (min-width: 1124px) {
  #bannerHero {
    -webkit-mask-image: none;
  }
  .section-banner__bottom{
    margin-block: 10px 30px;
  }

  .section-banner-container {
    &__content {
    margin-top: 50px;
    }

    &__anchor {
      padding-inline: 32px;
    }
  }


}

@media (min-width: 1364px) {
  .section-banner {
    &__background {
      img {
        height: 100%;
        position: absolute;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

@media (min-width: 1920px) {
  .section-banner {
  background: linear-gradient(to right, #1e1e1e 50%, #151515 50%);
  }

}
